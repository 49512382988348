<template>
  <div>
    <h2>ทร</h2>
    <input type="file" id="imageid" @change="handleFile" />
    <div id="canvas" style="display:none"></div>

    <div v-for="(obj, index) in objs" :key="index" class="card">
      <div class="row">
        <div class="col text-start">เลข 13 หลัก : {{ obj.cardNumber }}</div>
        <div class="col text-start">เพศ : {{ obj.gender }}</div>
      </div>
      <div class="row">
        <div class="col text-start">ชื่อ: {{ obj.name }}</div>
        <div class="col text-start">สกุล : {{ obj.lastName }}</div>
      </div>
      <div class="row">
        <div class="col text-start">ที่อยู่ : {{ obj.address }}</div>
        <div class="col text-start"></div>
      </div>
    </div>

    <textarea rows="50" cols="100" v-for="(result, index) in results" :key="index" :value="result">
    </textarea>
  </div>
</template>

<script>
import _ from "lodash";

import BaseConvert from "./convert-pdf-to-image.vue";
import utilsMixins from "./../_mixins/utils-mixins";
export default {
  extends: BaseConvert,

  data() {
    return {
      objs: [],
      results: []
    };
  },
  methods: {
    async handleFile(e) {
      let file = e.target.files[0];

      if (!file) {
        return;
      }

      //   let arr = await this.convertToImagesBase64(file);

      //   console.log("hhh", arr);
      this.convertToImagesBase64(file, reses => {
        this.results = [];
        this.objs = [];
        reses.forEach(res => {
          let url =
            "https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCv22vemzG4kY1ujcNMYCD0YKQGgAo3t7E";
          this.$http
            .post(
              url,
              {
                requests: [
                  {
                    image: {
                      content: `${res}`
                    },
                    features: [
                      {
                        type: "TEXT_DETECTION"
                      }
                    ]
                  }
                ]
              },
              { disabledBaseUrl: true }
            )
            .then(res => {
              let str = _.get(res, "responses[0].textAnnotations[0].description");
              console.log("str", str);
              this.results.push(str);

              this.objs.push(this.getModel(res));
            })
            .finally(() => {});
        });
      });
    },
    getModel(data) {
      //   let model = data.responses[0].textAnnotations[0];
      let obj = {};
      obj.cardNumber = data.responses[0].textAnnotations[2].description;
      obj.name = data.responses[0].textAnnotations[4].description;
      obj.lastName = data.responses[0].textAnnotations[5].description;
      obj.gender = data.responses[0].textAnnotations[6].description;
      obj.address = data.responses[0].textAnnotations[20].description;

      return obj;
    }
  }
};
</script>
